<template>
  <div>
    <div class="search">
      <div class="title">查看日志</div>
      <hr />
      <el-form :inline="true" class="top">
        <!-- <el-form-item label="昵称:">
            <el-input v-model="params.nickName" ></el-input>
        </el-form-item> -->
        <el-form-item label="修改类型:">
          <el-select v-model="params.logType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          <!-- <el-button type="primary"   @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" border style="width: 100%" align="center">
      <!-- <el-table-column type="selection" width="55"  align="center"> </el-table-column> -->
      <el-table-column type="index" width="50" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="修改时间" width="200" align="center">
      </el-table-column>
      <!-- <el-table-column label="头像 " width="180" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.headImg"
            :preview-src-list="[scope.row.headImg]"
          >
          </el-image>
        </template>
      </el-table-column> -->
      <el-table-column prop="strType" label="修改内容"  align="center">
      </el-table-column>
      <el-table-column prop="managerName" label="修改人" width="180" align="center">
      </el-table-column>
      <el-table-column
        prop="curContent"
        label="修改前内容"
        
        align="center"
      >
      </el-table-column>
       <el-table-column
        prop="endContent"
        label="修改后内容"
        
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="record(scope.row)">兑换记录</el-button>
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="code(scope.row)">生成小程序码</el-button>
            <el-button type="primary"  size="mini" @click="appoint(scope.row)">指定发放</el-button>
        </template>
        </el-table-column> -->
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { levelJournal } from "../../api/wanxiaoShop.js";
export default {
  data() {
    return {
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        logType: -1,
        levelId: 0,
      },
      options: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "新增",
          value: 0,
        },
        {
          label: "等级名称",
          value: 1,
        },
        {
          label: "等级排序",
          value: 2,
        },
        {
          label: "升级所需分销利润",
          value: 3,
        },
        {
          label: "一级分销比例",
          value: 4,
        },
        {
          label: "二级分销比例",
          value: 5,
        },
        {
          label: " 是否是外部店长",
          value: 6,
        },
      ],
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },

  created() {
    this.params.levelId = this.$route.query.id
    this.getList()
  },
  methods: {
    async getList() {
    //   this.params.beginTime = this.date ? this.date[0] : "";
    //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await levelJournal(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
        this.$message.warning(data.msg);
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>